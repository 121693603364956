import classNames from "classnames";
import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import Container from "~/components/common/container";
import HighlightedText from "~/components/common/highlighted-text";
import Icon from "~/components/common/icon";
import Logger from "~/services/logger/logger";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import styles from "./social-wall.module.scss";

type Props = {
  entry: Entry<unknown>;
};

export default function SocialWallEmbedded(props: Props) {
  const router = useRouter();
  const socialWallRef = useRef<HTMLDivElement>(null);
  const [scriptError, setScriptError] = useState(false);

  assertEditorialType<Editorials.SocialWall>(props.entry, "socialWall");
  const { title, listLinks, backgroundColor, socialWallId } = props.entry.fields;

  const inspectorMode = contentfulUtils.useInspectorMode(props.entry);

  useEffect(() => {
    const src = process.env.NEXT_PUBLIC_SOCIAL_WALL_WIDGET_URL;

    if (!src) {
      return;
    }

    try {
      let script = document.querySelector(`script[src="${src}"]`) as HTMLScriptElement;

      if (!socialWallRef.current?.hasChildNodes()) {
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.setAttribute(
          "data-wallurl",
          `https://my.walls.io/${socialWallId}?nobackground=1&layout=carousel&show_header=0&show_post_info=0&accessibility=0&lang=${router.locale}`
        );
        script.setAttribute("data-width", "100%");
        script.setAttribute("data-height", "400");
        script.setAttribute("data-lazyload", "1");
        script.onerror = function () {
          Logger.instance.error(`Failed to load ${src} of ${socialWallId}`);
          setScriptError(true);
        };

        socialWallRef.current?.appendChild(script);
      }
    } catch (err) {
      Logger.instance.error(`An error occurred while loading ${src} of ${socialWallId}`);
    }
  }, [router.locale, socialWallId]);

  if (scriptError) {
    return null;
  }

  return (
    <div className={classNames(styles.container, backgroundColor ? styles[backgroundColor] : undefined)}>
      <Container>
        <h2 {...inspectorMode?.getProps("title")} className={styles.title}>
          <HighlightedText text={title} />
        </h2>
        {listLinks ? (
          <div className={styles.wrapperLinks}>
            {listLinks.filter(isActiveEntry).map((link, i) => {
              const inspectorModeLink = contentfulUtils.useInspectorMode(link);

              return (
                <Link
                  href={textUtils.sanitizeContentfulUrl(link, router)}
                  target={link.fields.openOnANewTab ? "_blank" : undefined}
                  key={`${link.sys.id}-${i}`}
                  className={styles.link}
                  {...inspectorModeLink?.getProps("text")}
                >
                  {link.fields.icon ? (
                    <Icon svgMedia={link.fields.icon.fields.svgMedia} name={link.fields.icon.fields.svgIconId} />
                  ) : null}
                  {link.fields.text}
                </Link>
              );
            })}
          </div>
        ) : null}
        <div ref={socialWallRef} />
      </Container>
    </div>
  );
}
